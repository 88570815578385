
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './_theme_colors';

/* fonts */
@font-face {
  font-family: effra;
  src: url("./assets/fonts/effra-regular.ttf");
}

@font-face {
  font-family: effralight;
  src: url("./assets/fonts/EffraLight-Regular.ttf");
}

@font-face {
  font-family: efframedium;
  src: url("./assets/fonts/EffraMedium-Regular.ttf");
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mbody-fe-primary: mat.define-palette($custom-mbody-primary);
$mbody-fe-accent: mat.define-palette($custom-mbody-accent);

// The warn palette is optional (defaults to red).
$mbody-fe-warn: mat.define-palette($custom-mbody-warn);

$mbody-typography: mat.define-typography-config(
  $font-family: 'effra,sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mbody-fe-theme: mat.define-light-theme((
  color: (
    primary: $mbody-fe-primary,
    accent: $mbody-fe-accent,
    warn: $mbody-fe-warn,
  ),
  typography: $mbody-typography,
));

@include mat.core($mbody-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mbody-fe-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; 
  font-family: effra, sans-serif; font-style: normal;
}

.mat-typography h1 {
  font-family: effra;
  font-size: 1.7rem;
  font-weight: 500;
}

.mat-typography h2 {
  font-family: effra;
  font-size: 1.5rem;
  font-weight: 400;
}

.mat-typography h3 {
  font-family: effra;
  font-size: 1.2rem;
  font-weight: 300;
}

.multi-select-button {
  font-family: effra;
}

/* media queries */
@media screen and (min-width:768px) {
  h1 {
      font-size: 2.2rem;
  }
  h2 {
      font-size: 2rem;
  }
  h3 {
      font-size: 1.8rem;
  }
}

@media screen and (min-width:1024px) {
  h1 {
      font-size: 3rem;
  }
}

@media screen and (min-width:1200px) {
  h1 {
      font-size: 3.5rem;
  }
}